<template>
  <!-- <NuxtLink to="/" title="Home">Home</NuxtLink>
  <NuxtLink to="cars" title="Our cars">Our cars</NuxtLink>
  <NuxtLink to="" title="Sell your car">Sell your car</NuxtLink>
  <NuxtLink to="" title="Apply for finance">Apply for finance</NuxtLink>
  <NuxtLink to="" title="About us">About us</NuxtLink>
  <NuxtLink to="" title="Contact">Contact</NuxtLink> -->
  <NuxtLink to="/" title="Home">Home</NuxtLink>
  <NuxtLink to="/blog" title="Blog">Blog</NuxtLink>
  <NuxtLink to="/cars" title="Cars">Our cars</NuxtLink>
  <NuxtLink to="/tools" title="Tools">Tools</NuxtLink>
</template>
